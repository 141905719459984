<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Match the titration set up labels to their descriptions.</p>
      <img
        src="/img/assignments/ChemUCI1LC_Q4S1_q1_img.png"
        alt="Image of a titration set up"
        style="max-height: 300px"
      />

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 300px"
          class="my-1"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LC_Q4S1_Q1',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      options: [
        {text: 'Buret with titrant ($\\ce{NaOH_{(aq)}}$ or $\\ce{HCl_{(aq)}})$', value: 'buret'},
        {text: 'pH probe', value: 'probe'},
        {text: 'analyte solution (buffer)', value: 'analyte'},
        {text: 'stir bar', value: 'stirBar'},
        {text: 'clamps', value: 'clamps'},
        {text: 'stirrer/hot plate', value: 'stirrer'},
        {text: 'stopcock', value: 'stopcock'},
      ],
      letterOptions: [
        {text: 'A - ', inputName: 'input1'},
        {text: 'B - ', inputName: 'input2'},
        {text: 'C - ', inputName: 'input3'},
        {text: 'D - ', inputName: 'input4'},
        {text: 'E - ', inputName: 'input5'},
        {text: 'F - ', inputName: 'input6'},
        {text: 'G - ', inputName: 'input7'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
